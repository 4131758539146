<template lang="pug">
div.container
  div(v-if="!users")
    loading-spinner(message="Loading users...")

  div.container(v-if="users")
    div.row.justify-content-center
      h1 Change User Roles
    div.row.justify-content-center
      p: i Note: Please be careful not to change your own role to be lower than admin or you can't see this page anymore!

    br/
    br/
    div.row.justify-content-center
      div.col-md-8
        table.table.table-bordered
          thead.thead-dark
            tr
              th User
              th Current Role
              th New Role
          tbody
            tr(v-for="user in users")
              td {{user.name}}
              td {{user.roleName}}
              td
                form(name="roleSelectForm")
                  select(v-model="user.role" v-on:change="changeUserRole(user._id, user.role)")
                    option(value="1") New User
                    option(value="2") Volunteer
                    option(value="3") Board Member
                    option(value="4") Admin
                    option(value="5") Developer
                  b-button.ml-3(variant="danger" size="sm" @click="deleteUser(user._id)") Delete

  dev-data-dump(v-bind:data="users")
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      users: null,
      globalState: globalState
    }
  },
  methods: {
    getUsers: function () {
      this.axios.get('/api/roles')
        .then(res => (this.users = res.data))
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Fetching users failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    changeUserRole: function (id, newRole) {
      this.axios.put('/api/roles/' + id, { newRole: newRole })
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Role updated!',
            text: res.data.name + ' is now <b>' + res.data.roleName + '</b>'
          })
          this.getUsers()
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Updating role failed',
            text: err.response.data,
            duration: -1
          })
        })
    },
    deleteUser: function (id) {
      this.axios.delete('/api/roles/' + id)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Account deleted!'
          })
          this.getUsers()
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Deleting account failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  },
  mounted: function () {
    this.getUsers()
  }
}
</script>
